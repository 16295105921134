.gslb-sites-list-page {
    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        color: var(--cds-global-color-blue-700);
        font-weight: bold;
    }

    &__replication-mode-value {
        padding: 0 5px;
        color: var(--clr-color-neutral-700);
        font-weight: normal;
    }

    &__grid-name-column {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    &__alert-group {
        display: block;
        margin-bottom: 15px;
    }
}
